import React from 'react';
import './App.css';
import {Button} from "primereact/button";

import "primeflex/primeflex.css";
import "primereact/resources/primereact.min.css"; //core css
import "primeicons/primeicons.css"; //icons
import "primereact/resources/themes/lara-dark-teal/theme.css"; //theme

const SOCIAL_LINKS = {
    GITHUB: "https://github.com/damian-zaleski",
    LINKEDIN: "https://www.linkedin.com/in/damian-zaleski",
    GMAIL: "https://mail.google.com/mail/?view=cm&fs=1&to=contact@damianzaleski.com&su=From Landing Page",
    GOODREADS: "https://www.goodreads.com/user/show/147094213-damian"
}

function App() {

    const openInNewTab = (link: string) => {
        window.open(link);
    };

    return (
        <div className="surface-section px-4 py-8 md:px-6 lg:px-8">
            <div className="text-700 text-center">
                <span className="block text-6xl font-bold mb-1">Damian Zaleski</span>
                <div className="text-6xl text-primary font-bold mb-3">Software Engineer</div>
                <div className="text-700 text-2xl mb-5">I hope to find the time and courage to write more here in the
                    future, and until then I invite you to connect with me on the other platforms.
                </div>
                <div className="social-buttons">
                    <Button onClick={() => openInNewTab(SOCIAL_LINKS.GITHUB)}
                            className="github p-1"
                            aria-label="Github">
                        <i className="pi pi-github px-2"/>
                        <span className="px-3">Github</span>
                    </Button>

                    <Button onClick={() => openInNewTab(SOCIAL_LINKS.LINKEDIN)}
                            className="linkedin p-1"
                            aria-label="Linkedin">
                        <i className="pi pi-linkedin px-2"/>
                        <span className="px-3">Linkedin</span>
                    </Button>

                    <Button onClick={() => openInNewTab(SOCIAL_LINKS.GMAIL)}
                            className="gmail p-1"
                            aria-label="Gmail">
                        <i className="pi pi-inbox px-2"/>
                        <span className="px-3">Gmail</span>
                    </Button>

                    <Button onClick={() => openInNewTab(SOCIAL_LINKS.GOODREADS)} className="goodreads p-1"
                            aria-label="Goodreads">
                        <i className="pi pi-book px-2"/>
                        <span className="px-3">Goodreads</span>
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default App;
